


































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Users extends Vue {
  pagerHeader: any = {
    title: "管理员管理",
    desc: "添加、修改管理管理员，设置管理员角色以便控制其管理权限。",
    breadcrumb: ["系统", "管理员管理"]
  };
  // 模态框
  dialogFormVisible: boolean = false;
  // 过滤
  filter: any = {
    page: 1,
    size: 10,
    keyword: "",
    limitAgency: false
  };
  // 数据
  editModel: any = {
    id: 0,
    userName: null,
    nickName: null,
    roleNames: [],
    isActive: true, // 修改专用
    phoneNumber: null,
    password: null // 添加专用
  };
  // 匹配字段
  rules: any = {
    userName: [{ required: true, message: "请输入管理员名", trigger: "blur" }],
    nickName: [{ required: true, message: "请输入昵称", trigger: "blur" }],
    roleNames: [
      { required: true, message: "请选择角色", trigger: "change" },
      {
        validator: (rule: any, value: string, callback: Function) => {
          console.log(value);
          if (!value || value.length === 0) {
            callback(new Error("请选择角色"));
          } else {
            callback();
          }
        },
        trigger: "change"
      }
    ],
    password: [
      {
        validator: (rule: any, value: string, callback: Function) => {
          if (this.editModel.id === 0 && /^.{6,32}$/.test(value) === false) {
            callback(new Error("请输入密码（6-32位字）"));
          } else {
            callback();
          }
        },
        trigger: "change"
      }
    ]
  };
  loading: boolean = false;
  editLoading: boolean = false;
  roles: any[] = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
  agencyDialog: any = {
    show: false,
    id: "",
    title: "",
    parentAgencyId: "",
    rebateRatio: "",
    phoneNumber: "",
    nickName: "",
    submitLoading: false,
    userId: ""
  };
  activated() {
    this.getRoles();
    this.getData();
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    this.getRoles();
    this.getData();
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/Admin/GetPagedList", {
        params: this.filter
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  // 分页结束
  async getRoles() {
    try {
      let res = await this.$ajax.get("/api/services/app/Role/GetAll");
      if (res.data.success) {
        this.roles = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
  }

  openDialog() {
    this.resetForm();
    this.dialogFormVisible = true;
  }
  // 点击增加管理员
  async add(){
    this.loading = true;
    this.resetForm();
   this.dialogFormVisible = true;
   this.loading = false;
  }
  // 点击修改管理员
  async handleEdit(index: number, row: any) {
    this.resetForm();
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/Admin/Get", {
        params: { id: row.id }
      });
      if (res.data.success) {
        console.log(res.data.result);
        this.editModel.id = res.data.result.id;
        this.editModel.nickName = res.data.result.name;
        this.editModel.userName = res.data.result.userName;
        // this.editModel.phoneNumber = res.data.result.phoneNumber;
        this.editModel.isActive = res.data.result.isActive;
        this.editModel.roleNames = res.data.result.roleNames;

        this.dialogFormVisible = true;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  async resetPassword(user: any) {
    let res = await this.$confirm(
      "确定要将 " + user.name + " 的密码重置为123456？",
      "重置密码",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }
    );

    if (res == "confirm") {
      let res = await this.$ajax.post("/api/services/app/Admin/ResetPassword", {
        id: user.id,
        newPassword:'123456'
      });
      if (res.data.success) {
        this.$notify({
          title: "重置成功",
          message: "密码已重置为：123456",
          type: "success"
        });
      }
    }
  }
  async _delete(ids: any[]) {
    let res = await this.$confirm("此操作将删除数据, 是否继续?", "", {
      confirmButtonText: "是",
      cancelButtonText: "取消",
      type: "warning"
    });
    if (res == "confirm") {
      this.loading = true;
      try {
        let res = await this.$ajax.delete(
          "/api/services/app/Admin/Delete" + "?id=" + ids.join("&ids=")
        );

        if (res.data.success) {
          this.getData();
          this.$notify({
            title: "删除成功",
            message: "",
            type: "success"
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    }
  }
  resetForm() {
    this.editModel = {
      id: 0,
      userName: null,
      name: null,
      nickName:null,
      emailAddress: null,
      roleNames: [],
      isActive: true, // 修改专用111
      password: null // 添加专用
    };
  }

  submitForm() {
    (this.$refs.editForm as any).validate(async (valid: boolean) => {
      console.log('12344')
      if (valid) {
        this.editLoading = true;
        console.log('123')
        if (this.editModel.id === 0) {
          console.log('456')
          try {
            let res = await this.$ajax.post(
              "/api/services/app/Admin/Create",
              {
                
                userName:this.editModel.userName,
                name:this.editModel.nickName,
                isActive:this.editModel.isActive,
                password:this.editModel.password,
                roleNames:this.editModel.roleNames
                }
              
            );

            if (res.data.success) {
               this.dialogFormVisible = false;
              this.$notify({
                title: "成功",
                message: "管理员添加成功",
                type: "success"
              });
              
              this.editLoading = false;
              this.resetForm();
              this.getData();
            }
          } catch (error) {
            console.log(error);
          }
          this.editLoading = false;
        } else {
          
          try {
            let res = await this.$ajax.put(
              "/api/services/app/Admin/Update",  //404
              {
                id:this.editModel.id,
                 userName:this.editModel.userName,
                name:this.editModel.nickName,
                isActive:this.editModel.isActive,
                password:this.editModel.password,
                roleNames:this.editModel.roleNames
              }
            );

            if (res.data.success) {
              this.dialogFormVisible = false;
              this.$notify({
                title: "成功",
                message: "管理员修改成功",
                type: "success"
              });
              this.resetForm();
              this.getData();
            }
          } catch (error) {
            console.log(error);
          }

          this.editLoading = false;
        }
      }
    });
  }

  async submitAgency() {
    this.agencyDialog.submitLoading = true;
    try {
      let res = await this.$ajax.post("/api/services/app/UserAgency/AdminAdd", {
        userId: this.agencyDialog.userId
      });
      if (res.data.success) {
        this.agencyDialog.show = false;
        this.$message.success("开启成功");
        this.getData();
      }
    } catch (error) {}
    this.agencyDialog.submitLoading = false;
  }

  async showAgencyDetails(user: any) {
    let loading = this.$loading({
      text: "请稍后..."
    });
    try {
      let res = await this.$ajax.get(
        "/api/services/app/UserAgency/GetUserAgency",
        {
          params: {
            id: user.agencyId
          }
        }
      );

      if (res.data.success) {
        var data = res.data.result;
        this.agencyDialog.show = true;
        this.agencyDialog.id = user.agencyId;
        this.agencyDialog.title = "代理详细";
        this.agencyDialog.parentAgencyId = data.parentAgencyId;
        this.agencyDialog.rebateRatio = data.rebateRatio;
        this.agencyDialog.phoneNumber = user.phoneNumber;
        this.agencyDialog.nickName = user.nickName;
        this.agencyDialog.userId = user.id;
      }
    } catch (error) {}

    loading.close();
  }

  async showAddAgencyDialog(user: any) {
    let loading = this.$loading({
      text: "请稍后..."
    });
    try {
      let res = await this.$ajax.get(
        "/api/services/app/UserAgency/GetAgencyMaxIncomePercentage"
      );

      if (res.data.success) {
        this.agencyDialog.show = true;
        this.agencyDialog.id = "";
        this.agencyDialog.title = "开启代理";
        this.agencyDialog.parentAgencyId = "";
        this.agencyDialog.rebateRatio = res.data.result;
        this.agencyDialog.phoneNumber = user.phoneNumber;
        this.agencyDialog.nickName = user.nickName;
        this.agencyDialog.userId = user.id;
      }
    } catch (error) {}

    loading.close();
  }
}
